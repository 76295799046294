/*
:root {
  --active-brightness: 0.85;
  --border-radius: 5px;
  --box-shadow: 2px 2px 10px;
  --color: #118bee;
  --color-accent: #118bee15;
  --color-bg: #fff;
  --color-bg-secondary: #e9e9e9;
  --color-link: #118bee;
  --color-secondary: #920de9;
  --color-secondary-accent: #920de90b;
  --color-shadow: #f4f4f4;
  --color-table: #118bee;
  --color-text: #000;
  --color-text-secondary: #999;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --hover-brightness: 1.2;
  --justify-important: center;
  --justify-normal: left;
  --line-height: 1.5;
  --width-card: 285px;
  --width-card-medium: 460px;
  --width-card-wide: 800px;
  --width-content: 1080px;
}

header {
  text-align: center;
}

form {
  margin: 0 auto;
}

#url-input {
  width: 90%;
  height: 80%
}
*/
#error-messages {
  background-color: red;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  margin: 5px;
  /*
  height: 5rem;
  width: 5rem;
  */
}

/* CSS loading spinner from loading.io */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ---- */

/* Fade out animation for messages */
.fade-out {
  animation: fadeOut ease 5s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ---- */

/* Navbar */

nav {
  display: flex;
  justify-content: space-around;
}

nav > li {
  list-style: none;
}
/* ---- */

.scrolling-div {
  overflow-y: scroll;
  height: 10em;
  margin: 2em 0;
}

.scrolling-div > * {
  display: flex;
}

.scrolling-div input {
  margin-right: 1em;
}

/* Button icons */
button > img {
  margin-bottom: 0;
}

input[type="email"] {
  margin-right: 1em;
}

/* Reset url input margins */
input[type="url"] {
  margin-bottom: 0
}

.url-field {
  margin-bottom: 1em;
}

.url-field > input {
  width: 75%
}

#logged-in-container {
  display: flex;
  align-items: center;
}

.button-with-text {
  background-color: transparent !important;
  border-color: transparent !important;
  color: black !important;
  margin: 0;
}

.button-with-text:hover{
  text-decoration: underline;
  background-color: transparent !important;
  border-color: transparent !important;
  color: black !important;
}

#logged-in-container > button {
  background-color: transparent;
  border-color: transparent;
}

#logged-in-container > button > p {
  color: black;
  margin: 0;
}

#logged-in-container > button :hover {
  text-decoration: underline;
}

#logged-in-container > p {
  margin: 0
}

.small-text {
  font-size: 0.75em;
}

footer {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}